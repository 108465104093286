import React from 'react'
import { RouteObject, createBrowserRouter } from 'react-router-dom'
// custom components
import MainLayout from '~/src/Layouts/Main.Layout'
// constants
import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import ValidationLayout from '../Layouts/Validation.Layout'
import AppStore from './AppStore'
import ErrorBoundary from '../Components/ErrorBoundary/ErrorBoundary'

const SendRequestToDepository = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotPasswordOtpCodeForm" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/SendRequestToDepository/SendRequestToDepository.Page'
    ) as any
)

const ShowRequestStatus = React.lazy(
  () =>
    import(
      /* webpackChunkName: "UnlockAccountSetPassword" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/ShowRequestStatus/ShowRequestStatus.Page'
    ) as any
)

const ValidateSSO = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ValidateSSO" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/ValidateSSO/ValidateSSO.Page'
    ) as any
)

const RedirectToLogin = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotPasswordOtpCodeForm" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/RedirectToLogin/RedirectToLogin.Page'
    ) as any
)

const RedirectToInitiator = React.lazy(
  () =>
    import(
      /* webpackChunkName: "RedirectToInitiator" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/RedirectToInitiator/RedirectToInitiator.Page'
    ) as any
)

const ServiceNotAvailable = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ServiceNotAvailable" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/ServiceNotAvailable/ServiceNotAvailable.Page'
    ) as any
)

//SSO errors screens
const SomethingWentWrongError = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SomethingWentWrongError" */
      /* webpackMode: "lazy" */
      /* webpackPreload: true */
      '~/src/Pages/SomethingWentWrongError/SomethingWentWrongError.Page'
    ) as any
)

const isJourneyValidated = () => {
  const { isValidated } = AppStore.getState().securities
  if (!isValidated) {
    throw new Response('Not Found', { status: 404 })
  }
  return {}
}

const routeObj: RouteObject[] = [
  {
    element: <MainLayout />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: APP_ROUTES.REQUEST_TO_DEPOSITORY.pathname,
        element: <SendRequestToDepository />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.SHOW_REQUEST_STATUS.pathname,
        element: <ShowRequestStatus />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.REDIRECT_TO_LOGIN.pathname,
        element: <RedirectToLogin />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.REDIRECT_TO_INITIATOR.pathname,
        element: <RedirectToInitiator />,
        loader: isJourneyValidated
      },
      {
        path: APP_ROUTES.SERVICE_NOT_AVAILABLE.pathname,
        element: <ServiceNotAvailable />,
        loader: isJourneyValidated
      }
    ]
  } as RouteObject,
  {
    element: <ValidationLayout />,
    children: [
      {
        path: APP_ROUTES.VALIDATE_SSO.pathname,
        element: <ValidateSSO />
      },
      {
        path: APP_ROUTES.SOMETHING_WENT_WRONG.pathname,
        element: <SomethingWentWrongError />
      }
    ]
  } as RouteObject
]

const getAppRouter = () => createBrowserRouter(routeObj)

export default getAppRouter
